@import "~sbf-design-system/scss/base";
@import 'base'; // Imports the base stylesheet
@import "../websockets/websockets";
@import 'sections/fundraiser-league'; // League Status Box and Headings

$custom-background-breakpoint: 560px;

// Fundraiser Mypage Specific Styles go here - Only add things here that need to override the base.scss stylesheet.

.editable-mypage-ui {
  display: none; // don't display edit buttons unless the page is editable
}

header {
  margin-bottom: 0;
}

[role="main"] {
  margin-top: 0;
  padding-top: 0;
}

input,
button {
  outline: none;
  -webkit-appearance: none;
}

.national-partner { display: none; } // we do not display a national partner on the fundraiser mypage

// *------------------------------------*\
//   # Mypage Header Styles
//\*------------------------------------*/

.mypage-header {
  &::after,
  &::before {
    display: none;
  }

  background-image: url('fundraiser/header-bg.jpg');
  background-size: auto 240px;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: $sbf-white;
  border-bottom: 0 none;
  padding: 184px 0 0;

  a {
    @include link($sbf-blue);

    text-decoration: underline;
    font-weight: 600;
    border: none;
  }

  @media (min-width: $custom-background-breakpoint) {
    background-size: auto 270px;
  }

  @media (min-width: $medium) {
    background-size: cover;
    height: auto;
    padding: 0;
    color: $sbf-white;
    margin: 0 -8px;

    &::after,
    &::before {
      height: 100%;
      z-index: 1;
    }

    a {
      @include link($sbf-dark-yellow);
    }
  }

  @media (min-width: $large) {
    padding: 200px 0 40px;
  }

  .fundraiser-id.desktop-only {
    display: none;

    @media (min-width: $medium) {
      display: block;
      top: 35px;
    }

    @media (min-width: $large) {
      top: -157px;
    }
  }
}

.mypage-header__flag {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px 0 24px;
  margin-bottom: 0;

  @media (min-width: $medium) {
    padding: 16px 24px 80px 24px;
    box-shadow: none;
  }

  @media (min-width: $large) {
    padding: 32px 24px 50px 24px;
    display: block;
  }

  .flag__image {
    margin-bottom: 25px;
    margin-top: -133px;

    @media (min-width: $medium) {
      margin: 25px 0 30px 0;
    }
  }

  .photo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    padding: 3px;
    background-color: #FFF;
    box-shadow: 0 0 0 1px #CCC;

    @media (min-width: $medium) {
      box-shadow: none;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    box-shadow: none;
    margin: 0;
    width: auto;
    height: auto;
    padding: 0;
  }

  .flag__body {
    position: static;

    @media (max-width: $medium - 1) {
      .flag__details {
        background: #FFF;
        margin: 0 -24px;
        padding: 30px 24px 0;
        border-top: 1px solid $sbf-grey;

        .mobile-message {
          @include sbf-body('default-serif');

          font-weight: bold;
          margin-top: 35px;
          margin-bottom: 15px;

          a:link,
          a:visited {
            color: $sbf-edit-green;
          }

          a:hover,
          a:focus {
            color: $sbf-dark-green;
          }
        }
      }
    }

    @media (min-width: $medium) {
      position: relative;

      .flag__details {
        .mobile-message {
          display: none;
        }
      }
    }

    @media (max-width: $large - 1) {
      text-align: center;
    }
  }

  p { // overriding base-header.scss
    @include sbf-body('small-sans');

    @media (min-width: $medium) {
      @include sbf-body('default-sans');
    }
  }
}

.flag__image,
.flag__body {
  z-index: 2;

  @media (min-width: $medium) and (max-width: $large - 1) {
    display: block;
  }
}

@media (max-width: $medium - 1) {
  .mypage-header-inner,
  .mypage-header__flag {
    position: static;
  }

  .header-fundraiser-info.desktop-only {
    display: none;
  }
}

.mypage__description {
  @media (min-width: $medium) {
    max-width: 425px;
  }

  @media (min-width: $medium) and (max-width: $large - 1) {
    margin: 0 auto;
  }
}

.fundraiser-name-top-section-wrap-outer {
  width: 100%;
  margin: 6px auto 0;
  padding-bottom: 1px;

  @media (min-width: $small) {
    margin-bottom: 6px;
  }

  @media (min-width: $medium) {
    position: relative;
    top: 0;
  }
}

.fundraiser-name-top-section-wrap {
  padding: 1px 0;

  @media (max-width: $medium - 1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: $large) {
    margin-left: 0;
  }

  .mypage__description.fundraiser-name-top-section {
    @media (max-width: $medium - 1) {
      margin: 0 auto;
      width: 100%;
    }

    @media (min-width: $medium) {
      max-width: 425px;
      margin: 0 auto;
    }

    @media (min-width: $large) {
      margin: 0;
    }
  }

  h1 {
    margin-bottom: 0;
    color: $sbf-black;
    padding: 0;

    @media (min-width: $medium) {
      color: $sbf-white;
      padding: 0 0 8px;
    }

    @media (min-width: $large) {
      padding: 5px 25px 8px 0;
    }
  }
}

.fancy-buttons {
  bottom: 57px;
}

@media (min-width: $medium) {
  .mobile-fundraiser-info {
    display: none;
  }
}

.fundraiser-display-name {
  margin-bottom: 18px;

  .inline-noneditable {
    display: inline-block;
  }

  @include sbf-body('mini-sans');

  @media (min-width: $medium) {
    margin-bottom: 0;

    @include sbf-body('small-sans');

    max-width: 425px;
  }

  @media (min-width: $medium) and (max-width: $large - 1) {
    margin: 0 auto 18px;
  }

  h4 { font-weight: 500; }
}

// *------------------------------------*\
//   # Below Mypage Header Styles
//\*------------------------------------*/

.mypage__content {
  padding: 0;
  margin: 0 -7px;

  @media (min-width: $medium) {
    > div {
      display: flex;
      flex-flow: row;
    }
  }

  @media (min-width: $medium) {
    padding: 0;
  }

  @media (min-width: $large) {
    padding: 70px 0;
    margin: 0;
  }
}

.contact-buttons {
  @include sbf-body('default-serif');

  .button {
    border-radius: 50%;
    padding: 6px 4px 2px;
    width: 30px;
    height: 30px;
    text-align: center;
    margin: 0 0 0 4px;
    top: 2px;
  }

  .icon {
    height: 18px;
    width: 18px;
  }

  &.buttons-mobile {
    margin: 12px 0;

    @media (min-width: $medium) {
      display: none;
    }

    span.find-us {
      display: none;
    }
  }

  &.buttons-desktop {
    float: right;
    display: none;
    margin-left: 24px;

    @media (min-width: $medium) {
      display: block;
    }

    span.find-us {
      display: none;

      @media (min-width: $large) {
        margin-right: 4px;
        color: $sbf-black;
        display: inline-block;
        font-weight: 500;
      }
    }
  }
}

.mypage__sidebar {
  @media (min-width: $medium) and (max-width: $large - 1) {
    background: #FFF;
    padding: 0 25px 70px 25px;
    border-right: 1px solid #DCDCDC;
    border-bottom: 1px solid #DCDCDC;
  }

  .research-link {
    @include link($sbf-blue);

    text-decoration: underline;
    border: none;
  }
}

.mypage__main-column {
  padding: 0;

  @media (min-width: $medium) and (max-width: $large - 1) {
    border-bottom: 1px solid #DCDCDC;
    box-shadow: none;
    padding: 60px 25px 0 25px;
  }

  h2 {
    display: none;
  }

  @media (min-width: $medium) {
    padding: 0 25px 55px 25px;

    h2 {
      margin-bottom: 0;
      display: block;
    }
  }

  @media (min-width: $large) {
    padding: 0 24px 0 32px;
  }
}

// *------------------------------------*\
//   # Milestone Section
//\*------------------------------------*/

@import 'sections/milestones';

// *------------------------------------*\
//   # About Section (Event and Team Headline)
//\*------------------------------------*/

.fundraiser-about { // Tablet / Mobile About Section (above website message)
  display: none;

  h3 {
    @include sbf-heading('headline-18-sans');

    font-size: 20px; // This is a hack. It does not match the TK mixin. - AS
  }

  a {
    @include link($sbf-mint-green);

    border: none;
  }

  @media (min-width: $medium) {
    display: block;
  }
}

.fundraiser-about-mobile { // Mobile About Section (above Date & Location info)
  h3 {
    @include sbf-body('default-serif');

    font-weight: normal;
  }

  a {
    @include link($sbf-edit-green);

    text-decoration: none;
    font-weight: 600;
    border: none;
  }

  @media (min-width: $medium) {
    display: none;
  }
}

// *------------------------------------*\
//   # Website Message
//\*------------------------------------*/

.mypage__message-wrap {
  border-top: 1px solid #DCDCDC;
  background: $sbf-white;
  padding: 34px 24px 7px 24px;

  @media (min-width: $medium) {
    border-top: none;
    border-bottom: none;
    background: none;
    padding: 0 0 15px;
  }
}

.mypage__message {
  text-align: center;

  @media (min-width: $medium) {
    text-align: left;
  }
}

// *------------------------------------*\
//   # Website Video
//\*------------------------------------*/

.mypage-video {
  background: $sbf-white;
  padding: 15px 24px 30px;
  margin-bottom: 12px;
  border-bottom: 1px solid #DCDCDC;

  @media (min-width: $medium) {
    background: none;
    border: none;
    padding: 15px 0 0;
  }

  .responsive-video {
    margin: 0;
  }
}

// *------------------------------------*\
//   # Recent Donors
//\*------------------------------------*/

@media (max-width: $medium - 1) {
  .sidebar-stats {
    display: none;
  }

  .mobile-stats {
    display: block;
    background: $sbf-white;
    border-top: 1px solid #D6D6D6;
    border-bottom: 1px solid #D6D6D6;
    padding: 0 24px 30px;
    margin: 0 -7px;

    a {
      @include link($sbf-blue);

      text-decoration: underline;
      font-weight: 600;
      border: none;
    }

    p {
      @include sbf-body('default-serif');
    }
  }
  .mobile-stats:empty { display: none; }
}

// *------------------------------------*\
//   # Fundraising Block Shared Styles
//\*------------------------------------*/

@import 'sections/fundraising';

// *------------------------------------*\
//   # Fundraising Block Style Overrides
//   # Adding this now to hide the callout on desktop for mobile view
//\*------------------------------------*/

.is-editable.mypage-header .fundraising__block .editable-mypage-outlined {
  outline: 1px dashed #CCC;
}

.fundraising__block .header-only,
.fundraising__block p.fundraising--desktop {
  display: none;
}

.fundraising__goal .editable-mypage-outlined { display: inline-block; }//IE fix on

.fundraising__block .button--green {
  @include sbf-button($btnType: "sbf-dark-green", $btnSize: "small");
}

.fundraising__controls {
  margin-bottom: 5px;
  margin-top: 0;

  .event-id {
    padding-top: 12px;
    color: $sbf-black;

    @include sbf-heading('headline-12-sans');
  }
}

// mobile only
@media (max-width: $medium - 1) {
  .fundraising__block {
    p.fundraising--desktop,
    h3.fundraising--desktop {
      display: none;
    }
  }

  .mypage__fundraising {
    padding-top: 19px;
  }

  .fundraising__controls {
    .event-id {
      margin: 0 0 12px 0;
    }
  }

  .dashboard__fundraising {
    position: static;
    margin-bottom: 7px;
    overflow: hidden;

    progress[value] {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      @media (min-width: $medium) and (max-width: $large - 1) {
        margin-top: 8px;
        margin-bottom: 18px;
      }
    }
  }

  .mypage__fundraising__give-by-link {
    display: flex;
    justify-content: center;
    margin: 0 auto 16px auto;
    z-index: 2;

    @media (min-width: $medium) {
      margin: 0 auto;
    }

    p,
    ul {
      font-family: $sourceSans;
    }

    p {
      @include sbf-body('small-sans');

      margin: 0;
    }

    ul {
      display: flex;
      gap: 8px;
      margin: 0 0 0 4px;
      list-style-type: none;
    }
  }
}

// mobile/tablet only
@media (max-width: $large - 1) {
  .mypage_stat_callout {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    &::after {
      content: '';
      background-image: url('team/sb-hr.png');
      background-repeat: no-repeat;
      background-position: center center;
      height: 36px;
      display: block;
      margin: 3px auto 0;

      @media (min-width: $medium) and (max-width: $large - 1) {
        top: 10px;
        position: relative;
      }
    }

    a:link {
      font-weight: bold;
    }
  }

  .fundraising__raised.fundraising__raised {
    @include sbf-body('default-sans');

    font-weight: bold;
  }
}

@media (max-width: $medium - 1) {
  .fundraiser-button-container {
    width: 100%;
    text-align: center;
  }

  .fundraising__block .donate-button.full-width {
    width: 60%;
  }
}

// IE fix for donate button //
@media (min-width: $medium) {
  .fundraiser-button-container {
    width: 100%;
    text-align: center;
  }

  .fundraising__block .donate-button.full-width {
    width: 100%;
  }
}

@media (min-width: $x-large) {
  .fundraiser-button-container {
    width: 37%;
    text-align: center;
  }

  .fundraising__block .donate-button.full-width {
    width: 100%;
  }

  .fundraising__controls {
    margin-left: -1px; //bug on windows. prevent div from shifting below donate button before next breakpoint
  }
}

@media (min-width: $large) {
  .fundraising__block {
    margin-top: 50px;

    .condensed-header {
      margin-bottom: 20px;
    }
  }
}

@media (min-width: $x-large) {
  .mypage__fundraising {
    padding: 21px 16px;

    p.donation-form-download {
      display: block;
    }
  }

  .fundraising__stats {
    margin-top: 55px;
  }
}

.disable-fund-research {
  display: none;
}

.mypage_stat_callout {
  @include sbf-type($family: 'sourcesans', $type: 'body', $font-size: 18px, $bold: true);

  margin-bottom: 12px;

  a {
    @include link($sbf-blue);

    text-decoration: underline;
    border: none;
  }

  &::after {
    content: '';
    background-image: url('sbf-hr-gray.png');
    background-repeat: no-repeat;
    background-position: center center;
    height: 36px;
    max-width: 90%;
    display: block;
    margin: 10px auto 0;
  }

  @media (min-width: $medium) {
    &::after {
      display: none;
    }
  }
}

@media (min-width: $medium) {
  .mypage-header {
    .mypage_stat_callout,
    .fundraising__block {
      display: none;
    }
  }
}

@media (max-width: $medium - 1) {
  .mypage__content {
    .mypage_stat_callout,
    .fundraising__block {
      display: none;
    }

    margin: 0 -7px;
    padding-top: 36px;
  }
}

// *------------------------------------*\
//   # Gallery Page
//\*------------------------------------*/

@media (min-width: $medium) {
  .mypage--gallery {
    .mypage__main-column {
      h2 {
        margin-bottom: 0;
      }

      .back-to-mypage {
        margin: 12px 0 8px 0;
        padding-top: 0;
      }
    }
  }
}

.mypage--gallery .list-header {
  padding-left: 8px;
  padding-right: 8px;
}

.private-event-tooltip-popoup {
  display: none;
}
